import OrderNotificationCard from 'components/atoms/OrderNotificationCard'
import useIsMobile from 'hooks/useIsMobile'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ToastPosition, toast } from 'react-toastify'
import { readRecentOrders } from 'services/client'
import { OrderDetails } from 'types/orders'
import { UserRootState } from 'types/users'

interface OrderNotificationsListProps {
  eventId?: string
  hostId?: string
  eventType?: string
  position?: ToastPosition
  unAuthOnly?: boolean
  desktopOnly?: boolean
}

const isLocalhost = false // enable this for faster local development only
// const TOAST_INITIAL_DELAY = isLocalhost ? 0 : 4000
const TOAST_DURATION = 8000
const TOAST_GAPS = isLocalhost ? 0 : 4000
const TOAST_OPTIONS = {
  className: '!p-4 rounded-lg !bg-base-100 border-0 !shadow-lg',
  autoClose: TOAST_DURATION,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

const OrderNotificationsList = ({
  eventId,
  hostId,
  eventType,
  position = 'bottom-left',
  unAuthOnly = true,
  desktopOnly = false,
}: OrderNotificationsListProps) => {
  if (process.env.NEXT_PUBLIC_APP_ENV === 'development') return null

  const accessToken =
    useSelector((s: UserRootState) => s?.pr?.user?.auth?.stsTokenManager?.accessToken) || false
  const isMobile = useIsMobile('mobile')

  const [orders, setOrders] = useState<OrderDetails[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)

  const fetchRelevantOrders = async () => {
    const hideToast = accessToken && isMobile
    if (hideToast) return

    const orders = await readRecentOrders(eventId, hostId, eventType)
    console.log('🚩 @fetchRelevantOrders', orders)
    setOrders(orders)
  }

  useEffect(() => {
    const hideToast = accessToken && isMobile

    if (hideToast) setOrders([])
  }, [accessToken, unAuthOnly, isMobile, desktopOnly])

  useEffect(() => {
    fetchRelevantOrders()
  }, [eventId, hostId, eventType])

  useEffect(() => {
    if (!orders.length) return

    const intervalId = setInterval(() => {
      const order = orders[currentIndex]
      const toastId = 'order-notification'
      const Component = <OrderNotificationCard order={order} />
      const options = { ...TOAST_OPTIONS, toastId, position }

      toast(Component, options)
      setCurrentIndex((prev: number) => (prev + 1) % orders.length)
    }, TOAST_DURATION + TOAST_GAPS)

    return () => clearInterval(intervalId)
  }, [orders, currentIndex, position])

  return null
}

export default OrderNotificationsList

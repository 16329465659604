import Image from 'components/atoms/Image'
import { EventSlugToTypeMap } from 'components/organisms/Events/EventTypeData'
import Icons from 'consts/Icons'
import ROUTES from 'consts/Routes'
import Link from 'next/link'
import React from 'react'
import { OrderDetails } from 'types/orders'
import { durationBetween } from 'utils/DateTime'
import { stringToSlug } from 'utils/String'

const OrderNotificationCard = ({ order }: { order: OrderDetails }) => {
  const eventTypeSlug = stringToSlug(order?.event?.eventType) || 'music-events'

  const hrefConfig = {
    eventId: ROUTES.EVENT_BOOKING?.replace(':slug', order.eventId),
    hostId: ROUTES.BOOKING_WIDGET?.replace(':hostId', order.hostId),
    'event.eventType': ROUTES.EVENT_TYPE?.replace(':type', eventTypeSlug),
    global: ROUTES.EVENT_BOOKING?.replace(':slug', order.eventId), // same as eventId
  }

  const titleConfig = {
    eventId: order.event.name,
    hostId: order.host.hostTitle,
    'event.eventType': EventSlugToTypeMap[eventTypeSlug],
    global: order.event.name, // same as eventId
  }

  const priority = order?.priority || 'eventId'
  const linkHref = hrefConfig[priority] || '#'
  const linkTitle = titleConfig[priority] || '#'
  const totalTickets = order.summary?.ticketCount || 0
  const image = order?.user?.profileImage || order?.event?.images?.[0]?.url
  const isProfileImage = order?.user?.profileImage

  return (
    <div className='flex w-full items-center justify-between gap-2'>
      {isProfileImage && (
        <div className='relative aspect-square w-1/5'>
          <Image
            src={image}
            // alt={`Profile image of ${order?.user?.firstName}`}
            sizing={null}
            width={100}
            height={100}
            className='rounded-full object-cover'
          />
          <Icons.IFilledCircle className='absolute right-1 top-0 z-10 text-success' size={10} />
        </div>
      )}

      <div className='flex w-4/5 flex-col-reverse gap-2'>
        <p className='text-sm'>
          <strong>{order?.user?.firstName}</strong>
          <span>
            {' '}
            bought {totalTickets} {totalTickets > 1 ? 'tixs' : 'tix'} to{' '}
          </span>
          <Link className='custom-transition-a underline-offset-2 hover:underline' href={linkHref}>
            <span className='text-secondary/80'>{linkTitle}</span>
          </Link>
        </p>

        <div className='flex flex-row justify-between'>
          <span className='flex items-center gap-1 text-xs font-extralight tracking-wide'>
            <Icons.IFilledVerified className='text-info ' size={16} />
            VERIFIED
          </span>

          <span className='text-xs text-primary/40'> {durationBetween(order?.createdAt)}.</span>
        </div>
      </div>

      {!isProfileImage && (
        <div className='flex w-1/5'>
          <Link href={ROUTES.EVENT_BOOKING?.replace(':slug', order?.event?.eventId)}>
            <Image
              src={image}
              alt={`Event image for ${order?.event?.name}`}
              sizing={160}
              width={100}
              height={100}
              className='aspect-square rounded-md object-cover'
            />
          </Link>
        </div>
      )}
    </div>
  )
}

export default OrderNotificationCard
